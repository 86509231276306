import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const title = "Glossary";
export const _frontmatter = {};
const layoutProps = {
  title,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "glossary"
    }}>{`Glossary`}</h1>
    <p>{`The Serverless Handbook uses lots of terms that might be unfamiliar. I introduce them at first use and understand new words are hard.`}</p>
    <p>{`Refer here any time you encounter an unfamiliar word. If something's missing ping me on twitter @swizec.`}</p>
    <p>{`Words and phrases in alphabetical order.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ACID`}</strong>{` short for atomicity-consistency-isolation-durability, a model of database correctness`}</li>
      <li parentName="ul"><strong parentName="li">{`API`}</strong>{` short for Application Programming Interface, use to mean everything from the URL structure of a server to the public interface of a class or function`}</li>
      <li parentName="ul"><strong parentName="li">{`API Gateway`}</strong>{` the AWS service that routes HTTP requests from public URLs to internal services`}</li>
      <li parentName="ul"><strong parentName="li">{`ARN`}</strong>{` short for Amazon Resource Name, the globally unique identifier for AWS items`}</li>
      <li parentName="ul"><strong parentName="li">{`AWS`}</strong>{` amazon web services`}</li>
      <li parentName="ul"><strong parentName="li">{`CDN`}</strong>{` short for Content Distribution Network, an approach to serving static files that improves latency`}</li>
      <li parentName="ul"><strong parentName="li">{`CLI`}</strong>{` short for Command Line Interface, the terminal you type commands into`}</li>
      <li parentName="ul"><strong parentName="li">{`CORS`}</strong>{` a security protocol that limits which domains can access certain assets`}</li>
      <li parentName="ul"><strong parentName="li">{`CPU`}</strong>{` short for central processing unit, the part of computers that does most of the work`}</li>
      <li parentName="ul"><strong parentName="li">{`CRUD`}</strong>{` short for Create Read Update Delete, a typical set of operations apps need to support`}</li>
      <li parentName="ul"><strong parentName="li">{`CloudWatch`}</strong>{` the AWS service that collects and displays basic logs and metrics from every other service`}</li>
      <li parentName="ul"><strong parentName="li">{`DB`}</strong>{` short for database`}</li>
      <li parentName="ul"><strong parentName="li">{`DLQ`}</strong>{` short for Dead Letter Queue, a special queue used to hold bad messages for further debugging`}</li>
      <li parentName="ul"><strong parentName="li">{`DNS`}</strong>{` short for Domain Name System/Server, it translates domains to IP addresses`}</li>
      <li parentName="ul"><strong parentName="li">{`DX`}</strong>{` short for developer experience, a catch-all for how it feels to work with a technology`}</li>
      <li parentName="ul"><strong parentName="li">{`Go`}</strong>{` a popular lightweight language for server programming `}</li>
      <li parentName="ul"><strong parentName="li">{`I/O`}</strong>{` short for input/output, the process of reading and writing to an external medium like a hard drive or database`}</li>
      <li parentName="ul"><strong parentName="li">{`IP address`}</strong>{` the globally unique identifier for a computer on the internet`}</li>
      <li parentName="ul"><strong parentName="li">{`JSON`}</strong>{` a popular data format based on JavaScript objects`}</li>
      <li parentName="ul"><strong parentName="li">{`JVM`}</strong>{` short for Java Virtual Machine, the runtime environment for Java applications`}</li>
      <li parentName="ul"><strong parentName="li">{`QA`}</strong>{` short for Quality Assurance, used to mean both the process and the teams doing it`}</li>
      <li parentName="ul"><strong parentName="li">{`RDS`}</strong>{` short for Relational Database Service, an AWS service for hosting relational databases like Postgres and MySQL`}</li>
      <li parentName="ul"><strong parentName="li">{`S3`}</strong>{` the AWS static file hosting service`}</li>
      <li parentName="ul"><strong parentName="li">{`SNS`}</strong>{` short for Simple Notification Service, a messaging pub/sub service on AWS `}</li>
      <li parentName="ul"><strong parentName="li">{`SQS`}</strong>{` short for Simple Queue Service, a messaging queue service on AWS`}</li>
      <li parentName="ul"><strong parentName="li">{`SSL`}</strong>{` the encrypted communication layer used on the web`}</li>
      <li parentName="ul"><strong parentName="li">{`UI`}</strong>{` short for user interface`}</li>
      <li parentName="ul"><strong parentName="li">{`VPS`}</strong>{` short for Virtual Private Server, a type of shared hosting`}</li>
      <li parentName="ul"><strong parentName="li">{`apache`}</strong>{` a common web server popular with the open source community`}</li>
      <li parentName="ul"><strong parentName="li">{`azure`}</strong>{` Microsoft's cloud and serverless computing platform`}</li>
      <li parentName="ul"><strong parentName="li">{`blockchain`}</strong>{` a distributed ledger used for cryptocurrencies, data storage, and smart contracts`}</li>
      <li parentName="ul"><strong parentName="li">{`cache`}</strong>{` a fast data storage service or app for temporary data used to improve performance`}</li>
      <li parentName="ul"><strong parentName="li">{`chrome puppeteer`}</strong>{` a library used to automate browser tasks with Chrome`}</li>
      <li parentName="ul"><strong parentName="li">{`cloud function`}</strong>{` another name for a unit in function-as-a-service serverless computing`}</li>
      <li parentName="ul"><strong parentName="li">{`cloudfront`}</strong>{` AWS's CDN service`}</li>
      <li parentName="ul"><strong parentName="li">{`compute`}</strong>{` a fuzzy term for unit of computation used to talk about pricing and performance`}</li>
      <li parentName="ul"><strong parentName="li">{`devops`}</strong>{` is a set of practices that combines development and IT operations`}</li>
      <li parentName="ul"><strong parentName="li">{`docker`}</strong>{` a popular computer virtualization software and toolkit`}</li>
      <li parentName="ul"><strong parentName="li">{`dynamodb`}</strong>{` a wide-table NoSQL database service on AWS`}</li>
      <li parentName="ul"><strong parentName="li">{`edge workers`}</strong>{` a type of cloud function that works like a CDN and aims to reduce latency`}</li>
      <li parentName="ul"><strong parentName="li">{`exponential backoff`}</strong>{` a common approach to reducing load on a 3rd party service that is struggling`}</li>
      <li parentName="ul"><strong parentName="li">{`firebase`}</strong>{` Google's suite of backend-as-a-service offerings`}</li>
      <li parentName="ul"><strong parentName="li">{`git`}</strong>{` a common version control system`}</li>
      <li parentName="ul"><strong parentName="li">{`graphql`}</strong>{` an API protocol based on queries commonly used to  increase client power and flexibility`}</li>
      <li parentName="ul"><strong parentName="li">{`hashing function`}</strong>{` a secure method of uniquely encoding a string in a way that cannot be reversed`}</li>
      <li parentName="ul"><strong parentName="li">{`heroku`}</strong>{` a popular platform-as-a-service provider`}</li>
      <li parentName="ul"><strong parentName="li">{`http`}</strong>{` short for HyperText Transfer Protocol, the underlying protocol of the web`}</li>
      <li parentName="ul"><strong parentName="li">{`https`}</strong>{` the secure encrypted version of http that uses SSL`}</li>
      <li parentName="ul"><strong parentName="li">{`jamstack`}</strong>{` Javascript And Markdown stack, a stack of technologies used for modern static-first websites`}</li>
      <li parentName="ul"><strong parentName="li">{`kubernetes`}</strong>{` a popular toolkit for managing servers and containers`}</li>
      <li parentName="ul"><strong parentName="li">{`lambda function`}</strong>{` a unit in function-as-a-service serverless computing, synonym for cloud function`}</li>
      <li parentName="ul"><strong parentName="li">{`netlify`}</strong>{` a jamstack and serverless provider, popularized the jamstack approach`}</li>
      <li parentName="ul"><strong parentName="li">{`nginx`}</strong>{` a popular web server created in 2004 to address Apache's performance issues`}</li>
      <li parentName="ul"><strong parentName="li">{`nosql`}</strong>{` an umbrella term for databases that trade ACID compliance for specific performance or usability gains`}</li>
      <li parentName="ul"><strong parentName="li">{`PaaS/platform as a service`}</strong>{` a type of hosting that aims to solve infrastructure complexity`}</li>
      <li parentName="ul"><strong parentName="li">{`poison pill`}</strong>{` an unprocessable message or request`}</li>
      <li parentName="ul"><strong parentName="li">{`queue`}</strong>{` a data structure that processes messages in order, also used as shorthand for a service or application that acts as a queue`}</li>
      <li parentName="ul"><strong parentName="li">{`rainbow tables`}</strong>{` a pre-computed cache of hashes used to reverse hashed passwords`}</li>
      <li parentName="ul"><strong parentName="li">{`relational database`}</strong>{` a popular approach to storing business data since the 1970's, often synonymous with the concept of a database`}</li>
      <li parentName="ul"><strong parentName="li">{`rest`}</strong>{` a common approach to designing web APIs`}</li>
      <li parentName="ul"><strong parentName="li">{`server`}</strong>{` depending on context, a machine running software that serves internet requests, or the software itself`}</li>
      <li parentName="ul"><strong parentName="li">{`serverless`}</strong>{` an on-demand hosting environment`}</li>
      <li parentName="ul"><strong parentName="li">{`ssh`}</strong>{` short for secure shell, a protocol for remotely controlling services`}</li>
      <li parentName="ul"><strong parentName="li">{`upsert`}</strong>{` an operation that inserts a new object or updates an existing object with the same identifier`}</li>
      <li parentName="ul"><strong parentName="li">{`vercel`}</strong>{` a jamstack and serverless provider`}</li>
      <li parentName="ul"><strong parentName="li">{`yak shaving`}</strong>{` a useless activity that indirectly helps you solve a larger problem`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      